import React, { useState, useEffect, forwardRef } from 'react';
import { Dialog, Slide, IconButton, CardMedia, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MotionContainer } from 'components/animate';
import useResponsive from 'hooks/useResponsive';

// Fonction pour l'animation de Slide
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SlideInPopup = ({ url, type }) => {
  const isDesktop = useResponsive('up', 'md');
  const [open, setOpen] = useState(true);
  const [hasClosed, setHasClosed] = useState(false); // État pour vérifier si le popup a été fermé

  // Vérifie si le popup a déjà été fermé (via localStorage)
  useEffect(() => {
    const popupClosed = localStorage.getItem('popupClosed');
    console.log(popupClosed);
    if (!popupClosed) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 2000);

      return () => clearTimeout(timer); // Nettoie le timer
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setHasClosed(true);
    localStorage.setItem('popupClosed', 'false'); // Enregistre que le popup a été fermé
  };

  return (
    <div>
      {/* Popup avec animation Slide-In */}
      <Dialog open={open} TransitionComponent={Transition} keepMounted>
        <Box sx={{ padding: 3, width: 1, position: 'relative' }}>
          {/* Icône de fermeture en haut à droite */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 1,
              right: 1,
              color: 'grey.500',
              cursor: 'handle',
            }}
          >
            <CloseIcon />
          </IconButton>
          <MotionContainer>
            {type === 'video' ? (
              <video width={isDesktop ? 500 : 300} controls autoPlay loop muted>
                <source src="/assets/dubai-chocolate.mp4" type="video/mp4" />
              </video>
            ) : (
              <CardMedia
                component="img"
                image="/assets/slide.png"
                sx={{
                  width: '80vw',
                  height: '80vh',
                }}
              />
            )}
          </MotionContainer>
        </Box>
      </Dialog>
    </div>
  );
};

export default SlideInPopup;
