const de = {
  brand: 'ELITE STEAK HOUSE',
  welcome: `willkommen`,
  welcometo: `willkommen in`,
  baseline:
    'IN EINEM AUSSERGEWÖHNLICHEN RAHMEN, Genießen Sie sensationelles Fleisch.',
  coming_soon: `demnächst`,
  menu: `menü`,
  backmenu: `zum Menü zurückkehren`,
  enjoy_your_meal: `Guter Appetit`,
  information: 'Informationen',
  monday: 'Montag: 12.00 bis 22.00 Uhr (Küche)',
  exceptionopen: 'Außergewöhnliche Öffnung',
  tuesday: 'Dienstag, Mittwoch und Donnerstag: 12.00 bis 22.00 Uhr (Küche)',
  friday: 'Freitag, Samstag und Sonntag: 12:00 bis 22:30 Uhr (Küche)',
  service: 'Täglich durchgehender Service',
  servicetime: 'WIR HABEN 2 DIENSTE:',
  firstservicetime: '1. um 19 Uhr',
  secondservicetime: '2. um 21 Uhr',
  gateau: 'Kuchen von außen werden nicht akzeptiert!',
  following: 'Unsere sozialen Netzwerke',
  reservation: 'Reservierung',
  contact: 'Kontaktieren Sie uns',
  phone: '+33(0)3 88 60 26 24',
  ramadan: 'Spezielles Ramadan-Menü',
  footertext:
    'Herzliche Atmosphäre und raffiniertes Dekor, in dem unser Chefkoch eine Küche anbietet, die Ihnen das Beste an Fleisch bietet. Elite Steack House bietet Ihnen eine erstklassige Küche für Fleischliebhaber.',
};

export default de;
