const en = {
  brand: 'ELITE STEAK HOUSE',
  welcome: `welcome`,
  welcometo: `welcome to`,
  baseline: 'IN AN EXCEPTIONAL SETTING, enjoy a sensational meat.',
  coming_soon: `coming soon`,
  menu: `menu`,
  backmenu: `return to the menu`,
  enjoy_your_meal: `Enjoy your meal`,
  information: 'information',
  monday: 'Monday : 12h00 to 22h00 (kitchen)',
  exceptionopen: 'Exceptional opening',
  tuesday: 'Tuesday, Wednesday and Thursday : 12h00 to 22h00 (kitchen)',
  friday: 'Friday, Saturday and Sunday : 12h00 to 22h30 (kitchen)',
  service: 'Every day continuous service',
  servicetime: 'WE HAVE 2 SERVICES :',
  firstservicetime: '1st at 7pm',
  secondservicetime: '2nd at 9pm',
  gateau: 'Outdoor cakes are not accepted!',
  following: 'Our social networks',
  reservation: 'Reservation',
  contact: 'Contact-us',
  phone: '+33(0)3 88 60 26 24',
  ramadan: 'Special Ramadan Menu',
  footertext:
    'Warm atmosphere and refined decor in which our Chef proposes a cuisine that offers you the best of meat. Elite Steack House offers quality cuisine for meat lovers.',
};

export default en;
