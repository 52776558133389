import '../locales/i18n';
import '../utils/highlight';
import 'simplebar/src/simplebar.css';
import 'react-image-lightbox/style.css';
import '../utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import PropTypes from 'prop-types';
import cookie from 'cookie';
import Head from 'next/head';
import App from 'next/app';
import { MotionLazyContainer } from 'components/animate';
import { getSettings } from '../utils/getSettings';
import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext';
import ThemeProvider from '../theme';
import { ChartStyle } from '../components/chart';
import NotistackProvider from '../components/NotistackProvider';
import ThemeLocalization from '../locales';
import { Analytics } from '@vercel/analytics/react';
import { GoogleTagManager } from '@next/third-parties/google';
import Popup from 'components/Popup';

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  settings: PropTypes.object,
};

export default function MyApp(props) {
  const { Component, pageProps, settings } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="google-site-verification"
          content="dWfwGC4rj6Pe2t2b5wuz9V7dUxHb54GZhntCCiBj3mA"
        />
        <GoogleTagManager gtmId="GTM-K3QHR253" />
      </Head>
      <CollapseDrawerProvider>
        <MotionLazyContainer>
          <ThemeLocalization>
            <ThemeProvider>
              <NotistackProvider>
                <ChartStyle />
                <Popup type="video" />
                {getLayout(<Component {...pageProps} />)}
                <Analytics />
              </NotistackProvider>
            </ThemeProvider>
          </ThemeLocalization>
        </MotionLazyContainer>
      </CollapseDrawerProvider>
    </>
  );
}

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie
  );

  const settings = getSettings(cookies);

  return {
    ...appProps,
    settings,
  };
};
